import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["interval"]

  connect() {
    this.sortable = Sortable.create(this.element.querySelector('#intervals'), {
      animation: 150,
      ghostClass: 'blue-background-class',
      handle: '.sortable-handle', // Usa el handle para mover
      onEnd: this.end.bind(this) // Llama al método `end` cuando termina el reordenamiento
    })
  }

  end(event) {
    // Recolecta los intervalos y sus nuevas posiciones
    this.element.querySelectorAll('.nested-fields').forEach((el, index) => {
      const positionField = el.querySelector('.position-field');
      if (positionField) {
        positionField.value = index + 1; // Actualiza la posición de cada campo
      }
    })
  }
}
